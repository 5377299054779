import React from "react"
import Layout from "../../components/Layout/Layout"
import Mechanics from "../../components/Mechanics/Mechanics"

export default () => {
  return (
    <Layout title="Mechanics" flow="epharmacy" seoTitle="ePharmacy Mechanics">
      <Mechanics flow="epharmacy" nextRoute="/epharmacy/order" />
    </Layout>
  )
}
